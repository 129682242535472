import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { Icon } from '../layout/misc/Icon';
import { ProfileImage } from '../layout/misc/ProfileImage';
import { CandidateProfileTopSkills } from './profile/top-skills/CandidateProfileTopSkills';
import CandidatesTableActions from './shared/CandidatesTableActions';
import { fetchAndDownloadCandidateProfilePDF } from 'candidate-public-profile/pdf/utils/fetchAndDownload';

const CandidatesTable = observer(
	({
		data,
		visibleColumns,
		scorecardTemplates,
		sort,
		onSort,
		onGenerateLinkToken,
		onDelete,
		onActivate
	}) => {
		const history = useHistory();

		const shouldShowColumn = column =>
			visibleColumns.some(visibleColumn => visibleColumn.value === column);

		const isColumnSorted = column => {
			if (sort && sort.column === column) {
				const icon = sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
				return <Icon icon={icon} />;
			}
			return null;
		};

		const handleHeaderClick = column => {
			if (onSort) {
				onSort(column);
			}
		};

		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th
							className="is-sortable name-column"
							onClick={() => handleHeaderClick('name')}>
							Name {isColumnSorted('name')}
						</th>
						{shouldShowColumn('country') && (
							<th className="date-column is-hidden-mobile">Country</th>
						)}
						{shouldShowColumn('last_modified') && (
							<th
								className="date-column is-sortable is-hidden-mobile"
								onClick={() => handleHeaderClick('updatedAt')}>
								Last Modified {isColumnSorted('updatedAt')}
							</th>
						)}
						{shouldShowColumn('top_technologies') && (
							<th className="techs-column">Top Technologies</th>
						)}
						{shouldShowColumn('tags') && <th className="techs-column">Tags</th>}
						{shouldShowColumn('profile_completion') && (
							<th
								className="is-sortable is-hidden-mobile is-score is-right-align"
								onClick={() => handleHeaderClick('profileScore')}>
								<span
									className="tooltip has-tooltip-multiline is-tooltip-top"
									data-tooltip={`Profile Completion Score
										- Wizard: 5 points
										- Profile image: 5 points
										- Experience: 20 points
										- Education: 20 points
										- One Test Completed: 10 points
										- Active 'Tech' scorecard: 20 points
										- Inactive 'Tech' scorecard: 10 points
										- Active 'HR' scorecard: 20 points
										- Inactive 'HR' scorecard: 10 points
									`}>
									Profile Completion
								</span>
								{isColumnSorted('profileScore')}
							</th>
						)}
						{scorecardTemplates.map(scorecardTemplate => (
							<th className="is-hidden-mobile is-score is-right-align">
								<span
									className="tooltip is-tooltip-top"
									data-tooltip={scorecardTemplate.name}>
									{scorecardTemplate.name}
								</span>
							</th>
						))}
						<th style={{ width: '5%' }} />
					</tr>
				</thead>
				<tbody>
					{data.map(item => {
						return (
							<tr
								key={item.id}
								className={item.recordStatus === 'inactive' ? 'disabled' : ''}>
								<td className="has-text-weight-bold name-row">
									<div className="thumb-table">
										<div className="thumb-label">
											<figure className="image is-24x24 is-inline-block is-hidden-mobile">
												<span
													className="tooltip is-tooltip-right"
													data-tooltip={item.email}>
													<ProfileImage
														className="is-rounded"
														src={item.imageUrl ? `${item.imageUrl}` : null}
													/>
												</span>
											</figure>

											<div className="is-inline-block thumb-text">
												<a href={`/candidates/profile/${item.id}/edit`}>
													<span>{item.name ? item.name : ''}</span>
												</a>
											</div>
										</div>
									</div>
								</td>
								{shouldShowColumn('country') && (
									<td className="has-text-weight-bold is-hidden-mobile">
										<div className="thumb-label">
											<div className="subtitle is-7 thumb-text is-paddingless">
												<span
													className="tooltip is-tooltip-top"
													data-tooltip={item.country ? item.country.name : ''}>
													{item.country ? item.country.name : ''}
												</span>
											</div>
										</div>
									</td>
								)}
								{shouldShowColumn('last_modified') && (
									<td className="has-text-weight-bold is-hidden-mobile">
										<div className="thumb-label">
											<div className="subtitle is-7 thumb-text is-paddingless">
												<span
													className="tooltip is-tooltip-top"
													data-tooltip={moment(item.updatedAt).format('lll')}>
													{moment(item.updatedAt).format('ll')}
												</span>
											</div>
										</div>
									</td>
								)}
								{shouldShowColumn('top_technologies') && (
									<td className="has-text-weight-bold">
										<CandidateProfileTopSkills skills={item.topSkills} />
									</td>
								)}
								{shouldShowColumn('tags') && (
									<td className="has-text-weight-bold">
										{item.candidateTags.map(candidateTag => {
											return (
												<span
													key={candidateTag.tagId}
													className="tag is-blue add-margin-right">
													{candidateTag.tag.name}
												</span>
											);
										})}
									</td>
								)}
								{shouldShowColumn('profile_completion') && (
									<td className="has-text-weight-bold is-hidden-mobile score-row">
										<div
											className={`tag ${
												item.profileScore !== null &&
												(item.profileScore > 80
													? 'is-green'
													: item.profileScore > 40
														? 'is-yellow'
														: 'is-red')
											} subtitle is-7`}>
											{item.profileScore !== null
												? `${item.profileScore}%`
												: 'N/A'}
										</div>
									</td>
								)}
								{scorecardTemplates.map(scorecardTemplate => {
									const scorecard = item.scorecards.find(
										scorecard => scorecard.name === scorecardTemplate.name
									);

									const overallScore = Number(scorecard?.overallScore);
									return (
										<td className="has-text-weight-bold is-hidden-mobile score-row">
											<div
												className={`tag ${
													!!scorecard?.overallScore &&
													(overallScore >= 4
														? 'is-green'
														: overallScore >= 2.5
															? 'is-yellow'
															: 'is-red')
												} subtitle is-7`}>
												{!!scorecard?.overallScore
													? overallScore.toFixed(1)
													: 'N/A'}
											</div>
										</td>
									);
								})}
								<td className="actions-row">
									<div className="center-align is-flex-desktop">
										<CandidatesTableActions
											actions={[
												{
													label: 'Edit',
													title: 'Edit Profile',
													onClickHandler: () => {
														history.push(`/candidates/profile/${item.id}/edit`);
													},
													icon: 'pencil-alt'
												},
												{
													label: 'View Link',
													title: "View secret candidate's link",
													onClickHandler: async () => {
														if (onGenerateLinkToken) {
															await onGenerateLinkToken(item);
														}
													},
													icon: 'eye'
												},
												{
													label: 'Export to PDF',
													title: 'Export candidate profile to PDF',
													onClickHandler: async () => {
														fetchAndDownloadCandidateProfilePDF({
															candidateId: item.id
														});
													},
													icon: 'file-export'
												},
												item.recordStatus === 'active'
													? {
															label: 'Deactivate',
															title: 'Deactivate Candidate',
															onClickHandler: () => {
																if (onDelete) {
																	onDelete(item);
																}
															},
															icon: 'user-times'
														}
													: {
															label: 'Activate',
															title: 'Activate Candidate',
															onClickHandler: () => {
																if (onActivate) {
																	onActivate(item);
																}
															},
															icon: 'user-check'
														}
											]}
										/>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
);

export default CandidatesTable;
