import { candidatesStore } from 'components/candidates/CandidatesStore';
import { buildAbsoluteURL, convertToPNG, isURLAbsolute } from 'shared/utils';
import CandidateProfileResumePDF from '../CandidateProfileResumePDF';
import { pdf } from '@react-pdf/renderer';

export const fetchAndDownloadCandidateProfilePDF = async ({ candidateId }) => {
	const result = await candidatesStore.get(candidateId);

	const candidateData = result.data;

	const imageBuffer = candidateData.imageUrl
		? await convertToPNG(
				!isURLAbsolute(candidateData.imageUrl)
					? buildAbsoluteURL(candidateData.imageUrl)
					: candidateData.imageUrl
			)
		: null;

	const blob = await pdf(
		<CandidateProfileResumePDF
			candidateData={candidateData}
			candidateProcessedImage={imageBuffer}
		/>
	).toBlob();

	const url = URL.createObjectURL(blob);

	const link = document.createElement('a');
	link.href = url;
	link.download = `Prediktive Candidate - ${candidateData.name}`;
	document.body.appendChild(link);
	link.dispatchEvent(
		new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
	);
	link.remove();
	URL.revokeObjectURL(link.href);
};
