import React, { useEffect, useState } from 'react';
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Font,
	Image
} from '@react-pdf/renderer';
import moment from 'moment';
import { Workshifts } from 'components/timezones/Workshifts';
import { candidatesStore } from 'components/candidates/CandidatesStore';
import { buildAbsoluteURL, convertToPNG, isURLAbsolute } from 'shared/utils';
import CandidateProfileExperience from './sections/CandidateProfileExperience';
import CandidateProfileTestResults from './sections/CandidateProfileTestResults';
import CandidateProfileEducation from './sections/CandidateProfileEducation';
import CandidateProfileCertifications from './sections/CandidateProfileCertifications';

Font.register({
	family: 'Manrope',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F87jxeN7B.ttf',
			fontWeight: 200
		},
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F87jxeN7B.ttf',
			fontWeight: 300
		},
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf'
		},
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F87jxeN7B.ttf',
			fontWeight: 500
		},
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf',
			fontWeight: 600
		},
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F87jxeN7B.ttf',
			fontWeight: 700
		},
		{
			src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf',
			fontWeight: 800
		}
	]
});

export const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		paddingVertical: 30,
		fontSize: 10,
		fontFamily: 'Manrope',
		position: 'relative'
	},
	logo: {
		width: 112,
		position: 'absolute',
		top: 15,
		right: '50%',
		transform: 'translateX(50%)'
	},
	section: { marginVertical: 10, marginHorizontal: 30 },
	bio: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		gap: 24,
		position: 'relative',
		padding: 16,
		marginTop: 20
	},
	bioBackground: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		zIndex: -1
	},
	iconContainer: {
		padding: 6,
		backgroundColor: '#59F3D4',
		borderRadius: 5,
		aspectRatio: 1 / 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	header: {
		gap: 8,
		color: '#ffffff'
	},
	headerName: {
		fontSize: 22,
		fontWeight: 600
	},
	subheader: {
		flexDirection: 'row',
		gap: 8,
		fontSize: 20,
		fontWeight: 500,
		marginVertical: 20,
		color: '#4258DD',
		alignItems: 'center'
	},
	headerInfo: { flexDirection: 'row', gap: 8, paddingRight: 30 },
	headerLabel: {
		fontSize: 10,
		fontWeight: 600
	},
	headerValue: { fontSize: 10 },
	text: { fontSize: 10 },
	boldText: { fontWeight: 600 },
	flexRow: { flexDirection: 'row', gap: 8 },
	flexColumn: { flexDirection: 'column', gap: 8 },
	grayText: { color: '#565b6c' },
	imageProfile: {
		width: 100,
		height: 100,
		borderRadius: 50,
		alignSelf: 'flex-start',
		marginTop: 20
	},
	blueText: { color: '#4258DD' },
	tag: {
		padding: '2px 5px',
		fontSize: 7,
		backgroundColor: '#59F3D4',
		borderRadius: 12
	},
	scoreContainer: {
		borderTopLeftRadius: 6,
		backgroundColor: '#4258DD',
		padding: 8,
		color: '#ffffff',
		width: '50%'
	},
	percentileContainer: {
		borderTopRightRadius: 6,
		backgroundColor: '#59F3D4',
		padding: 8,
		width: '50%'
	},
	timeInfoContainer: {
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		border: '2px solid #DADEF1',
		flexDirection: 'row',
		padding: 8,
		justifyContent: 'space-between',
		fontSize: 8,
		gap: 4,
		width: '100%'
	},
	verticalDivider: {
		position: 'absolute',
		height: '300%',
		padding: '4px 0 0',
		alignSelf: 'stretch',
		flexDirection: 'column',
		alignItems: 'center',
		flex: 1,
		backgroundColor: '#FFFFFF',
		width: 12
	},
	dividerCicle: {
		width: 12,
		height: 12,
		border: '1.5px solid #5258FB',
		borderRadius: 50,
		backgroundColor: '#5258FB',
		position: 'absolute',
		left: -20
	},
	dividerLine: {
		borderRight: '2px dashed #314EBD',
		height: '100%',
		width: '100%'
	}
});

const CandidateProfileResumePDF = ({
	candidateData,
	candidateId,
	candidateProcessedImage
}) => {
	const [data, setData] = useState({});
	const [profilePictureImageBuffer, setProfilePictureImageBuffer] =
		useState(null);

	useEffect(() => {
		if (!candidateData) {
			(async () => {
				const result = await candidatesStore.get(candidateId);

				setData(result.data);
			})();
		}
	}, [candidateData, candidateId]);

	const candidate = candidateData || data;

	useEffect(() => {
		if (candidateProcessedImage) {
			return;
		}

		(async () => {
			const imageBuffer = candidate.imageUrl
				? await convertToPNG(
						!isURLAbsolute(candidate.imageUrl)
							? buildAbsoluteURL(candidate.imageUrl)
							: candidate.imageUrl
					)
				: null;

			setProfilePictureImageBuffer(imageBuffer);
		})();
	}, [candidateProcessedImage, candidate?.imageUrl]);

	const config = candidate?.candidateProfile;

	const hasExperiences =
		candidate.candidateExperiences?.filter(experience => !!experience.visible)
			?.length > 0;

	const hasTestResults =
		candidate.candidateTestResults?.filter(testResult => !!testResult.visible)
			?.length > 0;

	const hasEducations =
		candidate.candidateEducations?.filter(education => !!education.visible)
			?.length > 0;

	const hasCertifications =
		candidate.candidateCertifications?.filter(
			certification => !!certification.visible
		)?.length > 0;

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Image src="/logo-blue.png" style={styles.logo} />
				<View style={styles.bio}>
					<Image src="/bio-background.png" style={styles.bioBackground} />

					<View>
						{candidateProcessedImage || profilePictureImageBuffer ? (
							<Image
								style={styles.imageProfile}
								src={candidateProcessedImage || profilePictureImageBuffer}
							/>
						) : null}
					</View>
					<View style={[styles.header, { flex: 1 }]}>
						<Text style={styles.headerName}>{candidate.name}</Text>
						{(!config || config.showAboutMe) && (
							<View style={styles.headerInfo}>
								<Text style={styles.headerLabel}>Bio</Text>
								<Text style={styles.headerValue}>{candidate.aboutMe}</Text>
							</View>
						)}
						{(!config || config.showSkills) &&
						candidate.topSkills?.length > 0 ? (
							<View style={styles.headerInfo}>
								<Text style={styles.headerLabel}>Top Technologies</Text>
								<View style={[styles.flexRow, { flexWrap: 'wrap', flex: 1 }]}>
									{candidate.topSkills.map(topSkill => (
										<Text
											style={[
												styles.tag,
												styles.boldText,
												{ color: '#000000' }
											]}>
											{topSkill.name}
										</Text>
									))}
								</View>
							</View>
						) : null}
						{candidate.preferredWorkshifts?.length > 0 ? (
							<View style={styles.headerInfo}>
								<Text style={styles.headerLabel}>Preferred Workshift</Text>
								<View
									style={[
										styles.tag,
										styles.boldText,
										styles.blueText,
										{ backgroundColor: '#ffffffbf' }
									]}>
									<Text>
										<Workshifts
											showOnlyOptimalWorkshift
											textOnly
											candidate={candidate}
										/>
									</Text>
								</View>
								<Text style={styles.headerValue}>
									{moment.tz.guess().replace('-', ' ')}
								</Text>
							</View>
						) : null}
						{(!config || config.showLocation) && candidate.country ? (
							<View style={styles.headerInfo}>
								<Text style={styles.headerLabel}>Location</Text>
								<Text style={styles.headerValue}>{candidate.country.name}</Text>
							</View>
						) : null}
						{(!config || config.showRepos) &&
						candidate.candidateRepos?.length > 0 ? (
							<View style={styles.headerInfo}>
								<Text style={styles.headerLabel}>Repos</Text>
								<View style={[styles.flexColumn, { gap: 2 }]}>
									{candidate.candidateRepos.map(repo => (
										<Text style={styles.headerValue}>{repo.url}</Text>
									))}
								</View>
							</View>
						) : null}
					</View>
				</View>
				{(!config || config.showExperience) && hasExperiences && (
					<CandidateProfileExperience candidate={candidate} />
				)}

				{(!config || config.showTestResults) && hasTestResults && (
					<CandidateProfileTestResults candidate={candidate} />
				)}

				{(!config || config.showEducation) && hasEducations && (
					<CandidateProfileEducation candidate={candidate} />
				)}

				{(!config || config.showCertifications) && hasCertifications && (
					<CandidateProfileCertifications candidate={candidate} />
				)}
			</Page>
		</Document>
	);
};

export default CandidateProfileResumePDF;
