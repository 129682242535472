import { candidatesStore } from 'components/candidates/CandidatesStore';
import React, { Fragment } from 'react';

export class CandidateProfileEducation extends React.Component {
	displayValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}

	renderEducationStatus(education) {
		return (
			<div className="subtitle is-7 status">
				<span className="status-text">
					{this.displayValueFromOptions(
						education.status,
						candidatesStore.educationStatusOptions
					)}
				</span>
				{education.status === 'graduated' && education.graduationYear ? (
					<span>
						<i className="fas fa-graduation-cap" />
						<span className="ml-1">{education.graduationYear}</span>
					</span>
				) : null}
			</div>
		);
	}

	render() {
		return (
			<div className="content-container">
				{this.props.educations.map((education, index) => {
					return (
						<Fragment key={education.id}>
							<div className="description-content">
								<div
									className={`description-section education ${
										!education.visible ? 'is-disabled' : ''
									}`}>
									<div className="title is-5">{education.degree}</div>
									<div className="subtitle is-blue-text is-6">
										{education.institution}
									</div>
									<div className="additional-information-section">
										<div className="subtitle is-7 location">
											{education.location && (
												<span>
													<i className="fas fa-map-marker-alt" />
													<span>{education.location}</span>
												</span>
											)}
										</div>
										{this.renderEducationStatus(education)}
									</div>
								</div>
							</div>
						</Fragment>
					);
				})}
			</div>
		);
	}
}
