export const isMobile = () =>
	/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const convertToPNG = async imageUrl => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = 'anonymous';
		img.src = imageUrl;

		img.onload = () => {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext('2d');

			ctx.drawImage(img, 0, 0);
			const pngDataUrl = canvas.toDataURL('image/png');
			resolve(pngDataUrl);
		};

		img.onerror = error => reject(error);
	});
};

export const getNumberWithOrdinal = n => {
	const s = ['th', 'st', 'nd', 'rd'];
	const v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const isURLAbsolute = url => {
	return url.indexOf('://') > 0 || url.indexOf('//') === 0;
};

export const buildAbsoluteURL = url => {
	return `${process.env.REACT_APP_API}${url}`;
};
