import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import FontAwesomeIcon from '../FontAwesomeIcon';
import ViewNoWidow from '../ViewNoWidow';
import { styles } from '../CandidateProfileResumePDF';
import { faChartLine, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { getNumberWithOrdinal } from 'shared/utils';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

export default function CandidateProfileTestResults({ candidate }) {
	return (
		<View style={styles.section}>
			<ViewNoWidow style={styles.subheader} minPresenceAhead={105} wrap={false}>
				<View style={styles.iconContainer}>
					<FontAwesomeIcon
						faIcon={faChartLine}
						style={{
							color: '#4258DD',
							width: '15px'
						}}
					/>
				</View>

				<Text>Technical Tests</Text>
			</ViewNoWidow>
			<View
				style={[
					styles.flexRow,
					{
						gap: 16,
						flexWrap: 'wrap',
						alignItems: 'flex-end'
					}
				]}>
				{candidate.candidateTestResults
					?.filter(testResult => !!testResult.visible)
					?.map(testResult => {
						const showPercentile = testResult.test.sample_size >= 50;
						return (
							<View
								style={[
									styles.flexColumn,
									{ flex: '1 0 calc(25% - 10px)', maxWidth: '30%' }
								]}
								wrap={false}>
								<Text style={[styles.boldText]}>{testResult.test.name}</Text>
								<View>
									<View style={[styles.flexRow, { gap: 0 }]}>
										<View style={styles.scoreContainer}>
											<Text style={{ fontSize: 7 }}>Score </Text>
											<Text style={{ fontSize: 14 }}>
												{Math.round(testResult.score)}/100
											</Text>
										</View>
										<View style={styles.percentileContainer}>
											<Text style={{ fontSize: 7 }}>Percentile </Text>
											<Text style={{ fontSize: 14 }}>
												{showPercentile && testResult.percentile != null
													? getNumberWithOrdinal(testResult.percentile)
													: 'N/A'}
											</Text>
											<Text style={{ fontSize: 7 }}>
												{showPercentile && testResult.percentile != null
													? `n=${testResult.test.sample_size ? testResult.test.sample_size : 0}`
													: ' '}
											</Text>
										</View>
									</View>
									<View style={styles.timeInfoContainer}>
										<View
											style={[
												styles.flexRow,
												{ alignItems: 'center', gap: 4 }
											]}>
											<FontAwesomeIcon
												faIcon={faCalendar}
												style={{
													color: '#C9CDD9',
													width: '9px'
												}}
											/>
											<Text>
												{moment(testResult.takenAt).format('D MMM YYYY')}
											</Text>
										</View>
										<View
											style={[
												styles.flexRow,
												{ alignItems: 'center', gap: 4 }
											]}>
											<FontAwesomeIcon
												faIcon={faStopwatch}
												style={{
													color: '#C9CDD9',
													width: '9px'
												}}
											/>
											<Text>{testResult.timeTaken}&apos;</Text>
										</View>
									</View>
								</View>
							</View>
						);
					})}
			</View>
		</View>
	);
}
