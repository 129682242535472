import { candidatesStore } from 'components/candidates/CandidatesStore';
import React, { Fragment } from 'react';

/* eslint-disable indent */
export class CandidateProfileCertifications extends React.Component {
	getDisplayEducationStatus(value) {
		const educationStatus = candidatesStore.educationStatusOptions.find(
			status => status.value === value
		);
		return educationStatus.text;
	}

	renderCertificationStatus(certification) {
		return (
			<div className="subtitle is-7 status">
				<span className="status-text">
					{this.getDisplayEducationStatus(certification.status)}
				</span>
				{certification.status === 'graduated' &&
				certification.graduationYear ? (
					<span>
						<i className="fas fa-medal" />
						<span className="ml-1">{certification.graduationYear}</span>
					</span>
				) : null}
			</div>
		);
	}

	render() {
		return (
			<div className="content-container">
				{this.props.certifications.map((certification, index) => {
					return (
						<Fragment key={certification.id}>
							<div className="description-content">
								<div
									className={`description-section education ${
										!certification.visible ? 'is-disabled' : ''
									}`}>
									<div className="title is-5">{certification.degree}</div>
									<div className="subtitle is-blue-text is-6">
										{certification.institution}
									</div>
									<div className="additional-information-section">
										<div className="subtitle is-7 location">
											{certification.location && (
												<span>
													<i className="fas fa-map-marker-alt" />
													<span>{certification.location}</span>
												</span>
											)}
										</div>
										{this.renderCertificationStatus(certification)}
									</div>
								</div>
							</div>
						</Fragment>
					);
				})}
			</div>
		);
	}
}
