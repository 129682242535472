import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import FontAwesomeIcon from '../FontAwesomeIcon';
import ViewNoWidow from '../ViewNoWidow';
import { styles } from '../CandidateProfileResumePDF';
import {
	faBuilding,
	faGraduationCap,
	faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

export default function CandidateProfileEducation({ candidate }) {
	return (
		<View style={styles.section}>
			<ViewNoWidow style={styles.subheader} minPresenceAhead={65} wrap={false}>
				<View style={styles.iconContainer}>
					<FontAwesomeIcon
						faIcon={faGraduationCap}
						style={{
							color: '#4258DD',
							width: '15px'
						}}
					/>
				</View>

				<Text>Education</Text>
			</ViewNoWidow>
			<View style={[styles.flexColumn, { gap: 16 }]}>
				{candidate.candidateEducations
					?.filter(education => !!education.visible)
					?.map(education => {
						return (
							<View
								style={[styles.flexRow, { gap: 4, alignItems: 'flex-start' }]}
								wrap={false}>
								<Text>{'\u2022 '}</Text>
								<View style={styles.flexColumn} wrap={false}>
									<Text style={styles.boldText}>{education.degree}</Text>
									<View style={styles.flexRow}>
										<View
											style={[
												styles.flexRow,
												{ gap: 4, alignItems: 'center' }
											]}>
											<FontAwesomeIcon
												faIcon={faBuilding}
												style={{
													color: '#59F3D4',
													width: '9px'
												}}
											/>
											<Text>{education.institution}</Text>
										</View>
										{education.location && (
											<View
												style={[
													styles.flexRow,
													{ gap: 4, alignItems: 'center' }
												]}>
												<FontAwesomeIcon
													faIcon={faMapMarkerAlt}
													style={{
														color: '#59F3D4',
														width: '9px'
													}}
												/>
												<Text>{education.location}</Text>
											</View>
										)}
										{education.status === 'graduated' &&
										!education.graduationYear ? null : (
											<View
												style={[
													styles.flexRow,
													{ gap: 4, alignItems: 'center' }
												]}>
												<FontAwesomeIcon
													faIcon={faGraduationCap}
													style={{
														color: '#59F3D4',
														width: '13px'
													}}
												/>
												<Text>
													{education.status === 'graduated'
														? education.graduationYear
														: 'In Progress'}
												</Text>
											</View>
										)}
									</View>
								</View>
							</View>
						);
					})}
			</View>
		</View>
	);
}
