import { View } from '@react-pdf/renderer';
import React from 'react';

export default function ViewNoWidow({
	style,
	minPresenceAhead,
	wrap,
	children
}) {
	return (
		<>
			<View style={{ marginTop: 1 }}>
				{/* Necessary to make minPresenceAhead work */}
			</View>
			<View style={style} minPresenceAhead={minPresenceAhead} wrap={wrap}>
				{children}
			</View>
		</>
	);
}
