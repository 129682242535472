import { CandidateProfileImage } from 'components/candidates/profile/profile-image/CandidateProfileImage';
import React from 'react';
import AnchorIcons from './AnchorIcons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CandidateProfileResumePDF from 'candidate-public-profile/pdf/CandidateProfileResumePDF';
import { Icon } from './Icon';

const CandidatePublicProfileShortBio = React.forwardRef(
	({ candidate, handleAnchorClick, activeSection }, ref) => {
		return (
			<div className="short-bio-wrapper hidden" ref={ref}>
				<div className="short-bio">
					<CandidateProfileImage candidate={candidate} />
					<div className="name-container">
						<h2 onClick={e => handleAnchorClick(e, 'header')}>
							{candidate.name}
						</h2>
						<PDFDownloadLink
							className="download-pdf-button"
							document={<CandidateProfileResumePDF candidateData={candidate} />}
							fileName="resume.pdf"
							title="Export to PDF">
							{({ loading }) =>
								loading ? null : (
									<Icon className="is-small" icon="fa-solid fa-file-export" />
								)
							}
						</PDFDownloadLink>
						<AnchorIcons
							candidate={candidate}
							handleAnchorClick={handleAnchorClick}
							activeSection={activeSection}
						/>
					</div>
				</div>
			</div>
		);
	}
);

export default CandidatePublicProfileShortBio;
