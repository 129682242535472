import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import FontAwesomeIcon from '../FontAwesomeIcon';
import { faBriefcase, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { utils } from 'shared/stores/Utils';
import ViewNoWidow from '../ViewNoWidow';
import { styles } from '../CandidateProfileResumePDF';

export default function CandidateProfileExperience({ candidate }) {
	return (
		<View style={[styles.section, { overflow: 'hidden' }]}>
			<View style={[styles.subheader, { marginBottom: 12 }]}>
				<View style={styles.iconContainer}>
					<FontAwesomeIcon
						faIcon={faBriefcase}
						style={{
							color: '#4258DD',
							width: '15px'
						}}
					/>
				</View>

				<Text>Experience</Text>
			</View>
			<View style={[styles.flexColumn, { gap: 16 }]}>
				{candidate.candidateExperiences
					?.filter(experience => !!experience.visible)
					?.map((experience, index, array) => (
						<View wrap={index < array.length - 1}>
							<View style={[styles.verticalDivider, { marginTop: 8 }]}>
								{index < array.length - 1 ? (
									<View style={styles.dividerLine} />
								) : null}
							</View>
							<View
								style={[
									styles.flexColumn,
									{
										position: 'relative',
										left: 25,
										width: 510
									}
								]}>
								<View style={styles.flexColumn}>
									<ViewNoWidow
										minPresenceAhead={70}
										style={[styles.flexRow, { alignItems: 'center' }]}
										wrap={false}>
										<View style={styles.dividerCicle} />
										<Text
											style={[
												styles.boldText,
												styles.blueText,
												{ fontSize: 12 }
											]}>
											{experience.jobTitle}
										</Text>
										{experience.location && (
											<View style={[styles.flexRow, { gap: 4 }]}>
												<FontAwesomeIcon
													faIcon={faMapMarkerAlt}
													style={{
														color: '#59F3D4',
														width: '7px'
													}}
												/>
												<Text style={[styles.grayText, { fontSize: 8 }]}>
													{experience.location}
												</Text>
											</View>
										)}
									</ViewNoWidow>
									<View minPresenceAhead={45}>
										<Text>
											<Text style={styles.boldText}>{experience.company}</Text>{' '}
											| {utils.formatMonthYearDate(experience.from)} -{' '}
											{utils.formatMonthYearDate(experience.to)}
										</Text>
									</View>
									<Text orphans={1} style={[styles.grayText, styles.text]}>
										{experience.description}
									</Text>
									{experience.skills?.length > 0 ? (
										<View
											style={[styles.flexRow, { marginTop: 8 }]}
											wrap={false}>
											<Text
												style={[
													styles.grayText,
													styles.boldText,
													{ fontSize: 9 }
												]}>
												Technologies
											</Text>{' '}
											<View
												style={[styles.flexRow, { flexWrap: 'wrap', flex: 1 }]}>
												{experience.skills.map(skill => (
													<Text
														style={[
															styles.tag,
															styles.blueText,
															{ fontWeight: 500 }
														]}>
														{skill.name}
													</Text>
												))}
											</View>
										</View>
									) : null}
								</View>
								{experience.candidateExperienceProjects?.length > 0 ? (
									<View style={[styles.flexColumn, { marginLeft: 20 }]}>
										<ViewNoWidow minPresenceAhead={45}>
											<Text
												style={[
													styles.grayText,
													styles.boldText,
													{ fontSize: 9, marginBottom: 8 }
												]}>
												Projects
											</Text>
										</ViewNoWidow>
										{experience.candidateExperienceProjects.map(project => (
											<View>
												<ViewNoWidow minPresenceAhead={15}>
													<Text
														style={[
															styles.grayText,
															styles.boldText,
															{ fontSize: 8, marginBottom: 8 }
														]}>
														{project.title}
													</Text>
												</ViewNoWidow>
												<Text
													orphans={1}
													style={[styles.grayText, { fontSize: 8 }]}>
													{project.description}
												</Text>
											</View>
										))}
									</View>
								) : null}
							</View>
						</View>
					))}
			</View>
		</View>
	);
}
